
import { Component, Vue } from 'vue-property-decorator'
import MaintenanceRecord from '@/components/maintenanceRecord/Index.vue'

@Component({
  name: 'Record',
  components: { MaintenanceRecord }
})
export default class extends Vue {
}
